<template>
  <div>
    <div class="container-fluid">
      <PageTitle />

      <b-tabs>
        <b-tab title="List of Partners">
          <div v-if="isList" class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <h5 class="card-title">{{ pageTitle }} List</h5>
                </div>
                <div class="col-md-7">
                  <div class="row justify-content-end">
                    <div class="col-4">
                      <VSelect
                        v-model="filterStatus"
                        placeholder="-- All Status --"
                        :options="mrStatus"
                        :reduce="(v) => v.status"
                        label="status_description"
                      >
                      </VSelect>
                    </div>
                    <div class="col-4">
                      <VSelect
                        v-model="filterLevel"
                        placeholder="-- All Category Level --"
                        :options="mrCategory"
                        :reduce="(v) => v.id"
                        label="name"
                      >
                      </VSelect>
                    </div>
                    <div class="col-md-2">
                      <button class="btn btn-info" type="button" @click="doReset">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row mt-4">
                <div class="table-responsive">
                  <table class="table product-overview table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Category Level</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
                      <tr v-for="(v,k) in data.data" :key="k">
                        <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                        <td>
                          <a :href="v.url" target="_blank">{{ v.name }}</a>
                        </td>
                        <td>
                          <span class="d-block">{{ v.contact_phone }}</span>
                          <a target="_blank" :href="'mailto:' + v.contact_email" class="light fs-1rem text-wrap"><span class="d-block text-primary">{{ v.contact_email }}</span></a>
                        </td>
                        <td>
                          {{ v.category_level }}
                        </td>
                        <td>
                          <LabelStatus :status="v[statusKey]" />
                        </td>
                        <td>
                          <router-link 
                            v-if="moduleRole('Edit')"
                            :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                            class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                            <i class="ti-marker-alt"></i>
                          </router-link> 
                          <a 
                            v-if="moduleRole('Edit')"
                            href="javascript:;" 
                            class="text-inverse icon_action act_icon mx-1" v-tooltip="'Change Status'"
                            @click="doChangeStatus(k,v)">
                            <i class="ti-settings"></i>
                          </a> 
                          <a 
                            v-if="moduleRole('Delete')"
                            href="javascript:;" 
                            class="text-inverse icon_action act_icon" 
                            v-tooltip="'Delete'"
                            @click="doDelete(k,v)">
                            <i class="ti-trash"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="notFound"><td colspan="99" class="text-center py-4"><h4>{{notFound}}</h4></td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                  <div
                    class="pagination-flat float-right"
                    v-if="data.data !== false && data.data.length"
                  >
                    <Pagination
                      :data="data"
                      :limit="2"
                      @pagination-change-page="doPaginate"
                    >
                      <span slot="prev-nav">Previous</span>
                      <span slot="next-nav">Next</span>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="card">
            <div class="card-body">
              <h5 class="card-title mb-3">
                {{ isAdd ? "Add" : "Edit" }} {{ pageTitle }}
              </h5>

              <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                <form @submit.prevent="handleSubmit(doSubmitCRUD)">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="row">
                        <b-col md="12">
                          <BoField
                            label="Name"
                            placeholder="Hi Bank"
                            v-model="row.name"
                            :rules="mrValidation.name"
                          />
                        </b-col>
                        <b-col md="12">
                          <BoField
                            label="URL"
                            placeholder="https://"
                            v-model="row.url"
                            :rules="mrValidation.url"
                          />
                        </b-col>
                        <b-col md="4">
                          <BoField
                            label="Contact Name"
                            placeholder="John Doe"
                            v-model="row.contact_name"
                            :rules="mrValidation.contact_name"
                          />
                        </b-col>
                        <b-col md="4">
                          <BoField
                            label="Contact Email"
                            placeholder="mail@example.com"
                            v-model="row.contact_email"
                            :rules="mrValidation.contact_email"
                          />
                        </b-col>
                        <b-col md="4">
                          <BoField
                            label="Contact Phone"
                            placeholder="628***"
                            v-model="row.contact_phone"
                            :rules="mrValidation.contact_phone"
                          />
                        </b-col>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">
                              Category Level
                              <span class="text-danger mr5">*</span>
                            </label>

                            <VSelect
                              v-if="mrCategory"
                              v-model="row.mpc_id"
                              placeholder="-- All Category Level --"
                              :options="mrCategory"
                              :reduce="(v) => v.id"
                              label="name"
                            >
                            </VSelect>
                            
                            <VValidate
                              name="Level"
                              v-model="row.mpc_id"
                              :rules="mrValidation.mpc_id"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">
                              Status
                              <span class="text-danger mr5">*</span>
                            </label>
                            <div>
                              <InputRadio
                                v-model="row[statusKey]"
                                name="status"
                                option="Y"
                              />
                              <InputRadio
                                v-model="row[statusKey]"
                                name="status"
                                option="N"
                              />
                            </div>
                            <VValidate
                              name="Status"
                              v-model="row[statusKey]"
                              :rules="mrValidation[statusKey]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="row">
                        <b-col md="12">
                          <b-form-group>
                            <label>
                              Logo <span class="text-danger mr5">*</span>
                            </label>
                            <Uploader
                              :readonly="!moduleRole('Edit')"
                              v-model="row.fallback_img"
                              :param="{ thumbnail: true }"
                              :squarePreview="true"
                              type="logo_partner"
                              label="Image"
                              @data="(v) => (row.img = v)"
                            />
                            <VValidate
                              name="Image"
                              v-model="row.img"
                              :rules="mrValidation.img"
                            />
                          </b-form-group>
                        </b-col>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12 text-right">
                      <router-link
                        :to="{ name: $route.name }"
                        class="btn btn-rounded btn-light mr-2"
                        >Cancel</router-link
                      >
                      <button type="submit" class="btn btn-rounded btn-info">
                        {{ isAdd ? "Submit" : "Update" }}
                      </button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </b-tab>

        <template v-if="isList">
          <template v-for="(vlang,klang) in lang">
            <b-tab :title="`Page Settings - ${vlang.text}`" :key="klang">
              <bo-card title="Hero Image" footer-tag="footer">
                <b-row class="gutter-2">
                  <b-col md="3">
                    <bo-card-img
                      :src="uploader(((content['hero_' + vlang.value] || {}).value || {}).img)"
                      :title="((content['hero_' + vlang.value] || {}).value || {}).title"
                      :isTitleHtml="true"
                    >
                      <template #buttons>
                        <b-button
                          v-if="moduleRole('Edit')"
                          variant="secondary"
                          size="sm"
                          pill
                          @click="editHeroImage(content['hero_' + vlang.value])"
                          v-b-tooltip.hover="'Edit'"
                        >
                          <i class="fas fa-pencil-alt" />
                        </b-button>
                      </template>
                    </bo-card-img>
                  </b-col>
                </b-row>
              </bo-card>

              <div class="card">
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <h5 class="card-title">Content Section</h5>
                    </div>
                    <div class="col-6 ml-auto">
                      <div class="text-right">
                        <button
                          @click.prevent="toggleEditForm(`content_section_${vlang.value}`)"
                          class="btn btn-primary btn-rounded"
                        >
                          <i class="fas fa-edit m-r-10"></i> Update Content
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <ValidationObserver
                        v-slot="{ handleSubmit }"
                        :ref="`VFormContent${vlang.value}`"
                      >
                        <form
                          @submit.prevent="
                            handleSubmit(
                              updateContent(
                                'content-section-setting',
                                content['content_' + vlang.value],
                                `VFormContent${vlang.value}`,
                                false,
                                true
                              )
                            )
                          "
                        >
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <b-col md="12">
                                  <BoField 
                                    label="Title"
                                    placeholder="e.g. Official Sponsor"
                                    v-model="((content['content_' + vlang.value] || {}).value || {}).title"
                                    :rules="mrValidation.content_setting.title"
                                    :editFormId="`content_section_${vlang.value}`"
                                    :editFormValue="editFormId"
                                  />
                                </b-col>
                                <b-col md="12">
                                  <div class="form-group">
                                    <label>
                                      Description
                                      <span class="text-danger mr5">*</span>
                                    </label>
                                    <template v-if="editFormId == `content_section_${vlang.value}`">
                                      <b-form-textarea
                                        :id="`description${vlang.value}`"
                                        v-model="((content['content_' + vlang.value] || {}).value || {}).description"
                                        :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                        rows="5"
                                      ></b-form-textarea>
                                      <VValidate
                                        :vid="`description${vlang.value}`"
                                        name="Description"
                                        v-model="((content['content_' + vlang.value] || {}).value || {}).description"
                                        rules="required|min:10|max:500"
                                      />
                                    </template>
                                    <template v-else>
                                      <div v-html="((content['content_' + vlang.value] || {}).value || {}).description"></div>
                                    </template>
                                  </div>
                                </b-col>
                              </div>
                            </div>
                            <hr />
                            <b-col md="12" v-if="editFormId == `content_section_${vlang.value}`">
                              <div class="text-right mt-4">
                                <b-button
                                  type="button"
                                  @click="cancelEditForm"
                                  class="btn btn-rounded btn_light mr-2"
                                >
                                  Cancel
                                </b-button>
                                <b-button 
                                  v-if="moduleRole('Edit')"
                                  type="submit"
                                  variant="primary"
                                  class="btn-rounded"
                                >
                                  Update
                                </b-button>
                              </div>
                            </b-col>
                          </div>
                        </form>
                      </ValidationObserver>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </template>
          <template v-for="(vlangseo,klangseo) in lang">
            <b-tab :title="`SEO Settings - ${vlangseo.text}`" :key="`seo_${klangseo}`">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="card-title">SEO Settings</h5>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <ValidationObserver 
                        v-slot="{ handleSubmit }" 
                        :ref="`VFormSeo${vlangseo.value}`"
                      >
                        <form
                          @submit.prevent="
                            handleSubmit(
                              updateContent(
                                'seo-setting',
                                content['seo_' + vlangseo.value],
                                `VFormSeo${vlangseo.value}`,
                                false,
                                true
                              )
                            )
                          "
                        >
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-12 col-md-8">
                                  <div class="form-group">
                                    <label :for="`seoTitle${vlangseo.value}`">
                                      Title
                                      <span class="text-danger mr5">*</span>
                                    </label>
                                    <template v-if="editFormId == `seo${vlangseo.value}`">
                                      <b-form-input
                                        :id="`seoTitle${vlangseo.value}`"
                                        v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                        :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                      ></b-form-input>
                                      <VValidate
                                        :vid="`seoTitle${vlangseo.value}`"
                                        name="Title"
                                        v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                        rules="required|min:3|max:200"
                                      />
                                    </template>
                                    <template v-else>
                                      <p class="font-weight-bold">{{ ((content['seo_' + vlangseo.value] || {}).value || {}).title }}</p>
                                    </template>
                                  </div>
                                </div>
                                <div class="col-12 col-md-8">
                                  <div class="form-group">
                                    <label>
                                      Description
                                      <span class="text-danger mr5">*</span>
                                    </label>
                                    <template v-if="editFormId == `seo${vlangseo.value}`">
                                      <b-form-textarea
                                        :id="`seoDescription${vlangseo.value}`"
                                        v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                        :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                        rows="5"
                                      ></b-form-textarea>
                                      <VValidate
                                        :vid="`seoDescription${vlangseo.value}`"
                                        name="Description"
                                        v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                        rules="required|min:10|max:500"
                                      />
                                    </template>
                                    <template v-else>
                                      <div v-html="((content['seo_' + vlangseo.value] || {}).value || {}).description"></div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row" v-if="moduleRole('Edit')">
                            <div class="col-12">
                              <hr />
                              <div class="text-right">
                                <template v-if="editFormId == `seo${vlangseo.value}`">
                                  <button
                                    type="button"
                                    @click="editFormId = ''"
                                    class="btn btn-rounded btn-light mr-2"
                                  >
                                    Cancel
                                  </button>
                                  <button type="submit" class="btn btn-rounded btn-primary">
                                    Save
                                  </button>
                                </template>
                                <template v-else>
                                  <button
                                    type="button"
                                    @click="editFormId = `seo${vlangseo.value}`"
                                    class="btn btn-rounded btn-primary"
                                  >
                                    Ubah
                                  </button>
                                </template>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ValidationObserver>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </template>
        </template>

        <ValidationObserver v-slot="{ handleSubmit }" ref="VFormHeroImage">
          <b-modal
            id="heroImageModal"
            size="md"
            title="Edit Hero Image"
            @ok.prevent="
              handleSubmit(
                updateContent(
                  'hero-image',
                  inputHeroImage,
                  'VFormHeroImage',
                  'heroImageModal'
                )
              )
            "
          >
            <template #default>
              <div class="infoHTML"></div>
              <b-form 
                @submit.prevent="
                    handleSubmit(
                      updateContent(
                        'hero-image',
                        inputHeroImage,
                        'VFormHeroImage'
                      )
                    )
                  "
              >
                <b-row>
                  <b-col md="12">
                    <BoField 
                      label="Sub Title"
                      placeholder="e.g. MEDIA PARTNER"
                      v-model="((inputHeroImage || {}).value || {}).sub_title"
                      :rules="mrValidation.sub_title"
                    />
                  </b-col>
                  <b-col md="12">
                    <BoField 
                      label="Title"
                      placeholder="e.g. Jakarta Running Festival 2024"
                      v-model="((inputHeroImage || {}).value || {}).title"
                      :rules="mrValidation.hero_image.title"
                    />
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>
                        Image <span class="text-danger mr5">*</span>
                      </label>
                      <Uploader
                        :readonly="!moduleRole('Edit')"
                        v-model="((inputHeroImage || {}).value || {}).fallback_img"
                        :param="{ thumbnail: true }"
                        :squarePreview="true"
                        type="hero_image"
                        label="Image"
                        @data="v => ((inputHeroImage || {}).value || {}).img = v"
                      />
                      <VValidate
                        name="Image"
                        v-model="((inputHeroImage || {}).value || {}).img"
                        :rules="mrValidation.hero_image.img"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>
                        Small Image <span class="text-danger mr5">*</span>
                      </label>
                      <Uploader
                        :readonly="!moduleRole('Edit')"
                        v-model="((inputHeroImage || {}).value || {}).fallback_img_small"
                        :param="{ thumbnail: true }"
                        :squarePreview="true"
                        type="hero_small"
                        label="Image"
                        @data="v => ((inputHeroImage || {}).value || {}).img_small = v"
                      />
                      <VValidate
                        name="Image"
                        v-model="((inputHeroImage || {}).value || {}).img_small"
                        :rules="mrValidation.hero_image.img_small"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <BoField 
                      label="Alt Image"
                      placeholder="e.g. Hero Image"
                      v-model="((inputHeroImage || {}).value || {}).alt_img"
                      :rules="mrValidation.hero_image.alt_img"
                    />
                  </b-col>
                  <hr />
                </b-row>
              </b-form>
            </template>

            <template #modal-footer="{ ok, cancel }">
              <b-button variant="outline-secondary" @click="cancel()">
                Cancel
              </b-button>
              <b-button variant="info" @click="ok()">
                Submit
              </b-button>
            </template>
          </b-modal>
        </ValidationObserver>
      </b-tabs>

    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import assign from "lodash/assign";

import BoCard from '@/components/BoCard.vue'
import BoCardImg from '@/components/BoCardImg.vue'

import { extend } from "vee-validate";

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    BoCard,
    BoCardImg
  },
  data() {
    return {
      idKey: "id",
      statusKey: "is_active",
      mrStatus: [],
      mrCategory: [],
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        }
      ],
      editFormId: "",
      content: [],
      inputHeroImage: {}
    };
  },
  computed: {
    filterStatus: {
      get() {
        return this.filter.status;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          status: newValue,
        };
        this.doFilter();
      },
    },
    filterLevel: {
      get() {
        return this.filter.level;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          level: newValue,
        };
        this.doFilter();
      },
    },
  },
  methods: {
    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },

    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      this.$bvModal.show('heroImageModal')
    },
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
  watch: {
    $route: {
      handler() {
        this.apiGet();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.add_hero {
  min-height: 130px;
  border: 2px dotted rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>